.thank-you-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #f3f4f6; /* Light background color */
    padding: 20px;
  }
  
  .thank-you-card {
    background-color: #ffffff; /* White background for the card */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center; /* Center text alignment */
    max-width: 500px;
    width: 100%;
  }
  
  .thank-you-heading {
    font-size: 2em;
    color: #333333; /* Darker color for heading */
    margin-bottom: 20px;
  }
  
  .thank-you-message,
  .order-note {
    font-size: 1.1em;
    color: #666666; /* Gray color for paragraphs */
    margin-bottom: 20px;
  }
  
  .back-home-button {
    background-color: #007bff; /* Primary button color */
    color: #ffffff; /* White text color */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .back-home-button:hover {
    background-color: #0056b3; /* Darker button color on hover */
  }
  