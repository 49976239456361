@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}
