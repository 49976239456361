.customOrderParentContainer {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
}

.customOrderContainer {
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Set a max width for the container */
  margin: 0 auto; /* Center align the container */
  min-height: 100dvh;
  height: 100%;
}

.productsHeading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.categorySelector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: center;
}

.categoryLabel {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
}

.categoryIcon {
  margin-right: 8px;
  color: #007bff;
}

.categoryDropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  outline: none;
  transition: border-color 0.3s;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Responsive grid layout */
  gap: 20px;
  margin-top: 20px;
  padding: 0 20px;
  justify-items: center; /* Center items in the grid */
  align-items: stretch; /* Ensure all items stretch to fill grid height */
}

.product-card {
  /* Apply to both product and add custom cards */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  height: 100%; /* Full height for all cards */
}

.product-card:hover,
.add-custom-card:hover {
  /* Same hover effect for both types of cards */
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.add-custom-card {
  background-color: #f0f0f0;
  border: 2px dashed #007bff;
  cursor: pointer;
  display: flex; /* Use Flexbox for centering */
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  width: 100%;
  max-width: 300px; /* Set max width */
  height: 100%; /* Full height for all cards */
  padding: 20px; /* Add padding for spacing */
  transition: background-color 0.3s, border-color 0.3s; /* Transition for hover effects */
}

.add-custom-card:hover {
  background-color: #e0f0ff;
  border-color: #0056b3;
}

.add-icon {
  font-size: 48px;
  color: #007bff;
  margin-bottom: 10px; /* Spacing between icon and text */
}

.add-text {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center; /* Center the text */
}

.product-image-container {
  width: 100%;
  height: 180px; /* Set a fixed height for the image container */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product-info {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  color: #333;
}

.product-price {
  color: #007bff;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.product-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto;
  transition: background-color 0.3s;
  font-size: 14px;
  width: auto;
  align-self: center;
}

.product-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .product-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }

  .product-card,
  .add-custom-card {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .categorySelector {
    flex-direction: column;
  }

  .categoryDropdown {
    width: 100%;
  }

  .product-card,
  .add-custom-card {
    padding: 10px;
  }
}
