.customize-order-container {
    padding: 20px;
    max-width: 1200px;
    /* Ensure a max-width for the container */
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customize-order-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.image-upload-section,
.order-form-section {
    flex: 1;
    min-width: 350px;
    /* Increased min-width for better balance */
    max-width: 500px;
    /* Set a max-width to maintain consistency */
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-heading {
    font-weight: bold;
    font-size: 1.8em; /* Larger font size for main heading */
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .section-heading {
    font-weight: bold;
    font-size: 1.4em; /* Adjusted size for section headings */
    color: #333;
    margin-bottom: 15px;
    text-align: left; /* Align headings to the left */
  }
  
  .form-label {
    font-weight: bold;
    font-size: 1.2em; /* Consistent size for labels */
    color: #333;
    margin-bottom: 8px;
    display: block; /* Ensure labels take full width */
    text-align: left; /* Align labels to the left */
  }
  

.image-upload-input {
    margin-top: 10px;
    margin-bottom: 20px;
}

.image-preview {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 15px;
}

.material-type-dropdown,
.query-box,
.shipping-details-form {
    text-align: left;
    /* Align form labels to the left */
    margin-bottom: 20px;
}

.material-dropdown,
.query-textarea,
.shipping-details-form input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
}

.shipping-details-form h3 {
    margin-bottom: 15px;
}

.shipping-details-form input {
    margin-bottom: 10px;
}

.generate-order-button {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    font-weight: bold;
}

.generate-order-button:hover {
    background-color: #0056b3;
}

.order-notes {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    color: #856404;
    text-align: center;
    font-weight: bold;
    /* Bold text for emphasis */
}


.color-picker-section {
    margin: 20px 0; /* Adds spacing around the section */
    display: flex; /* Aligns the label and input horizontally */
    align-items: center; /* Centers them vertically */
    gap: 10px; /* Adds space between label and input */
  }
  
  .form-label-color {
    font-size: 16px; /* Increases font size */
    color: #333; /* Sets the label color */
    margin-right: 10px; /* Adds a small space to the right of the label */
  }
  
  .color-picker-input {
    width: 100px; /* Sets the width of the color picker */
    height: 30px; /* Sets the height of the color picker */
    border: none; /* Removes default border */
    cursor: pointer; /* Changes cursor to pointer on hover */
    padding: 5px; /* Adds some padding inside the input */
    border-radius: 5px; /* Rounds the corners of the input */
    background-color: #f5f5f5; /* Sets a light background color */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  }
  
  .color-picker-input:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Darkens shadow on hover */
  }
  