.LoginSignUpContainer {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    position: fixed;
    top: 0%;
    left: 0;
  }
  
  .LoginSignUpBox {
    background-color: white;
    width: 25vw;
    height: 70vh;
    box-sizing: border-box;
    overflow: hidden;
  }
  .login_signUp_toggle {
    display: flex;
    height: 3vmax;
  }
  .login_signUp_toggle > p {
    color: rgba(0, 0, 0, 0.678);
    font: 300 1vmax "Roboto";
    transition: all 0.5s;
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 100%;
  }
  .login_signUp_toggle > p:hover {
    color: tomato;
  }
  
  .LoginSignUpBox > div > button {
    background-color: tomato;
    height: 3px;
    width: 50%;
    border: none;
    transition: all 0.5s;
  }
  
  .loginForm,
  .signUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 2vmax;
    justify-content: space-evenly;
    height: 70%;
    transition: all 0.5s;
  }
  
  .signUpForm {
    transform: translateY(-100%) translateX(-100vmax);
  }
  
  .loginForm > div,
  .signUpForm > div {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .loginForm > div > input,
  .signUpForm > div > input {
    padding: 1vmax 4vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 4px;
    font: 300 0.9vmax cursive;
    outline: none;
  }
  
  .loginForm > div > svg,
  .signUpForm > div > svg {
    position: absolute;
    transform: translateX(1vmax);
    font-size: 1.6vmax;
    color: rgba(0, 0, 0, 0.623);
  }
  
  .loginForm > a {
    color: rgba(0, 0, 0, 0.651);
    text-decoration: none;
    align-self: flex-end;
    transition: all 0.5s;
    font: 500 0.8vmax "Gill Sans";
  }
  
  .loginForm > a:hover {
    color: black;
  }
  
  #registerImage > img {
    width: 3vmax;
    border-radius: 100%;
  }
  #registerImage > input {
    display: flex;
    padding: 0%;
  }
  
  #registerImage > input::file-selector-button {
    cursor: pointer;
    width: 100%;
    z-index: 2;
    height: 5vh;
    border: none;
    margin: 0%;
    font: 400 0.8vmax cursive;
    transition: all 0.5s;
    padding: 0 1vmax;
    color: rgba(0, 0, 0, 0.623);
    background-color: rgb(255, 255, 255);
  }
  
  #registerImage > input::file-selector-button:hover {
    background-color: rgb(235, 235, 235);
  }
  
  .loginBtn,
  .signUpBtn {
    border: none;
    background-color: tomato;
    color: white;
    font: 300 0.9vmax "Roboto";
    width: 100%;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
  }
  
  .loginBtn:hover,
  .signUpBtn:hover {
    background-color: rgb(179, 66, 46);
  }
  
  .shiftToLeft {
    transform: translateX(-100%);
  }
  .shiftToNeutral {
    transform: translateX(0%);
  }
  
  .shiftToNeutralForm {
    transform: translateX(0%) translateY(-100%);
  }
  
  .shiftToRight {
    transform: translateX(100%);
  }
  
  @media screen and (max-width: 600px) {
    .LoginSignUpContainer {
      background-color: white;
    }
    .LoginSignUpBox {
      width: 100vw;
      height: 95vh;
    }
    .login_signUp_toggle {
      height: 5vmax;
    }
    .login_signUp_toggle > p {
      font: 300 1.5vmax "Roboto";
    }
  
    .loginForm,
    .signUpForm {
      padding: 5vmax;
    }
  
    .loginForm > div > input,
    .signUpForm > div > input {
      padding: 2.5vmax 5vmax;
      font: 300 1.7vmax cursive;
    }
  
    .loginForm > div > svg,
    .signUpForm > div > svg {
      font-size: 2.8vmax;
    }
  
    .loginForm > a {
      font: 500 1.8vmax "Gill Sans";
    }
  
    #registerImage > img {
      width: 8vmax;
      border-radius: 100%;
    }
  
    #registerImage > input::file-selector-button {
      height: 7vh;
      font: 400 1.8vmax cursive;
    }
  
    .loginBtn,
    .signUpBtn {
      font: 300 1.9vmax "Roboto";
      padding: 1.8vmax;
    }
  }