.contactContainer {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: grid;
  place-items: center;
  background-color: white;
  position: fixed;
}

.mailBtn {
  text-decoration: none;
  transform: translateX(-100vw);
  animation: mailBtnAnimation 2s forwards;
}

.mailBtn > button {
  text-decoration: none;
  font: 200 2vmax "Roboto";
  cursor: url("https://img.icons8.com/color/48/000000/edit--v2.png"), pointer;
  padding: 2vmax;
}

@keyframes mailBtnAnimation {
  to {
    transform: translateX(0);
  }
}
.contact {
  padding: 10% 2%;
  margin: auto;
  background-color: beige;
}

.contact .d-flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.contact-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.contact-form button {
  width: 90%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Responsive styling */
@media (max-width: 768px) {
  .form-container {
    max-width: 90%;
  }

  .contact {
    padding: 15% 0;
  }
}

@media (max-width: 480px) {
  .form-container {
    max-width: 100%;
    padding: 20px;
  }
  .contact {
    padding: 15% 0;
  }

  .contact-form h2 {
    font-size: 20px;
  }

  .contact-form button {
    font-size: 14px;
  }

  /* Change display to block on mobile screens */
  .contact .d-flex {
    display: block;
  }
  .contact iframe {
    width: 100%;
  }
}
