.speedDial {
    position: fixed;
    right: 1vmax;
    top: 8vmax;
  }
  
  .speedDialIcon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }

  @media screen and (max-width: 800px) {
    .logoReactNavbar {
        width: 100% !important;
    }

  } 
  @media screen and (max-width: 1000px) {
    .speedDial {
      position: fixed;
      right: 1vmax;
      top: 11vmax;
    }

    @media screen and (max-width: 550px) {
      .speedDial {
        position: fixed;
        right: 1vmax;
        top: 15vmax;
      }
}
  }



  /* New Navbar  */

  * {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    padding: 0;
    /* height: 200vh; */
  }
  
  .navbar {
    font-size: 16px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .navbar.hidden {
    transform: translateY(-100%);
  }
  
  .logo {
    margin-left: 0.3125rem;
  }
  
  .logo img {
    width: 7.5rem;
  }
  
  .menu,
  .menu-icons {
    margin-right: 0.3125rem;
    display: flex;
    align-items: center;
  }
  
  .menu a,
  .menu-icons a {
    color: black;
    text-decoration: none;
    margin-left: 1.25rem;
    font-size: 1.125rem;
    position: relative;
    overflow: hidden;
  }
  
  .menu a:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 0.125rem;
    width: 75%;
    background: black;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s linear;
  }
  
  .menu a:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  .menu-icons a .icon-text {
    display: none;
  }
  
  .bottom-nav {
    display: none;
    justify-content: space-around;
    padding: 0.5rem 0;
    background-color: white;
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .bottom-nav a {
    color: black;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
  }
  
  .bottom-nav a i {
    font-size: 1.5rem;
    display: block;
  }
  
  .bottom-nav a .icon-text {
    display: block;
    font-size: 0.875rem;
  }
  
  @media screen and (max-width: 54rem) {
    .menu {
      display: none;
    }
    .bottom-nav {
      display: flex;
    }
  }
  